/*
 * Keyframes
 * =========
 *
 */

@keyframes fadeup {
  0% {
    transform: translateY(15px);

    opacity: 0;
  }
  100% {
    transform: translateY(0);

    opacity: 1;
  }
}

@keyframes bg {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(0, 0, 0, 0.95);
  }
}

@keyframes shrink {
  0% {
    min-height: 100vh;
  }
  100% {
    min-height: 90vh;
  }
}
