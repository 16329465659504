/*
 * Footer
 * ======
 *
 */

footer {
  padding: var(--unit-double) 0;

  @include medium {
    margin-top: var(--unit);
  }
  @include small {
    margin-top: 0;
  }

  & > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include medium {
      justify-content: center;
      flex-flow: row wrap;
    }
  }

  span {
    line-height: 1;

    color: var(--meta-color);

    @include medium {
      font-size: 80%;

      display: block;

      width: 100%;
      margin-top: var(--unit);

      text-align: center;
    }
  }

  ul {
    display: flex;

    margin: 0;
    padding: 0;

    list-style: none;

    @include medium {
      display: block;
    }
  }

  a {
    text-decoration: none;

    color: var(--meta-color);

    @include medium {
      display: inline-block;
    }

    img {
      display: block;

      width: 16px;
      /* Don't want anything to be cut off. */
      padding: 1px;

      @include medium {
        width: 32px;
      }
    }
  }

  li {
    line-height: 1;

    display: flex;
    align-items: center;

    @include medium {
      display: inline-block;

      margin-right: var(--unit);

      &:last-child {
        margin-right: 0;
      }
    }

    &:not(.email) {
      transition: opacity 0.2s cubic-bezier(0.64, 0.04, 0.35, 1);

      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    a {
      padding: 0 1.25rem;

      @include small {
        padding: 0 0.5rem;
      }
    }

    & + li:before {
      position: relative;

      display: inline-block;

      width: 1px;
      height: 24px;

      content: '';

      background-color: rgba(0, 0, 0, 0.1);

      @include medium {
        display: none;
      }
    }
  }

  .email {
    margin-right: 1.5rem;

    @include medium {
      display: block;

      margin-right: 0;
      margin-bottom: var(--unit);

      text-align: center;
    }

    a {
      padding: 0;
    }
  }

  .col {
    @include medium {
      margin-right: 0;

      text-align: center;

      flex: 1 1 100%;
    }
  }
}

img[alt='Eintracht Frankfurt'] {
  height: 22px;
  width: 24px;
}
