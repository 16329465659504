/*
 * Landing
 * =======
 *
 */

.post:hover .cover:after {
  // box-shadow: inset 0 0 0 10px #fff;
  background-color: rgba(#14151c, 0.85);

  @include small {
    background-color: rgba(#14151c, 0);
  }
}

.post:active .cover:after {
  @include small {
    background-color: rgba(#14151c, 0.3);
  }
}

.post:active {
  transform: scale(0.95);
}

.post:hover h2 {
  transform: translateY(0);

  opacity: 1;

  @include small {
    opacity: 0;
  }
}

.cover {
  position: relative;

  overflow: hidden;

  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;

  h2 {
    position: absolute;
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    margin: 0;
    padding: var(--unit);

    transition: 1s transform cubic-bezier(0.19, 1, 0.22, 1),
      1s opacity cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateY(20px);
    text-align: center;

    opacity: 0;
    color: #fff;
    text-shadow: 0 0 40px rgba(#14151c, 0.1);
  }

  &:after {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';
    transition: 0.5s box-shadow cubic-bezier(0.19, 1, 0.22, 1),
      1s background-color cubic-bezier(0.19, 1, 0.22, 1);
    backface-visibility: hidden;

    background-color: rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 0 #fff;
  }

  .gatsby-image-outer-wrapper {
    transition: 1s transform cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.post {
  position: relative;

  margin-bottom: var(--unit);

  transition: 0.2s transform cubic-bezier(0.19, 1, 0.22, 1);

  &:hover .cover .gatsby-image-outer-wrapper {
    transform: scale(1.05);

    @include small {
      transform: scale(1);
    }
  }

  a {
    text-decoration: none;
  }
}

.dev-only {
  font-size: 60%;
  font-weight: var(--bold);
  line-height: 1;

  position: absolute;
  top: var(--unit-half);
  right: var(--unit-half);

  padding: 0.5em;

  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: #fff;
  border-radius: 4px;
  background-color: var(--accent-color);
}
