/*
 * Variables
 * =========
 *
 */

// For breakpoint mixins.
$small-width: 600px;
$medium-width: 1024px;
$large-width: 1140px;
$unit-half: 1.5rem;

:root {
  --sans-serif-fallback: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  --sans-serif: 'Syne Bold', 'Nitti Grotesk', Graphik, Isidora Sans, Tofino,
    var(--sans-serif-fallback);
  --serif: 'NB', IBM Plex Serif, Tramuntana Text Pro, Tiempos Text, Georgia, serif;
  --bold: 800;
  --text-color: #14151c;
  --meta-color: #8b939c;
  --fade-color: #f8f8f9;
  --paragraph-color: #30363d;
  --border-color: #eee;
  --accent-color: #6256f6;
  --backdrop: rgba(26, 28, 70, 0.1);
  --unit: 3rem;
  --unit-half: calc(var(--unit) / 2);
  --unit-quarter: calc(var(--unit) / 4);
  --unit-double: calc(var(--unit) * 2);

  --content-width: 1140px;
  --outer-rim: 2000px;
  --col-half: calc(50% - var(--unit-half));

  --h1-min: 20px;
  --h1-max: 54px;
  --viewport-min: 480;
  --viewport-max: 1400;

  @include medium {
    --unit: 2rem;
  }

  @include small {
    --unit: 1.5rem;
  }
}
