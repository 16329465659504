/*
 * Navigation
 * ==========
 *
 */

.topbar {
  width: 100%;
  margin-bottom: var(--unit-double);
  padding: var(--unit) 0 0;

  transition: background-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out;

  @include small {
    position: fixed;
    z-index: 10;
    top: 0;

    height: 48px;
    margin-top: 0;
    padding: 0;

    color: var(--text-color);
    background-color: rgba(255, 255, 255, 0.95);

    backdrop-filter: blur(5px);
  }

  .col {
    @include small {
      flex: 1;
    }
  }

  a {
    text-decoration: none;
  }

  nav {
    @include small {
      display: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    list-style: none;

    @include small {
      width: 100%;
    }
  }

  li {
    position: relative;
    z-index: 0;

    display: inline-block;
    overflow: visible;

    transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    color: var(--meta-color);

    @include small {
      display: block;

      animation: fadeup 0.5s ease-in-out backwards;
    }

    & + li {
      margin-left: var(--unit);

      @include small {
        margin-top: var(--unit-half);
        margin-left: 0;
      }
    }
  }

  & > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;
  }
}

.name {
  position: relative;
  z-index: 1;

  transition: 0.5s color cubic-bezier(0.19, 1, 0.22, 1);

  color: var(--meta-color);

  a {
    color: var(--text-color);
  }
}

.menuicon {
  position: relative;
  z-index: 1;

  display: none;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;

  width: 48px;
  height: 48px;
  margin-right: calc((48px - 21px) / -2);

  @include small {
    display: flex;
  }

  .top,
  .bottom {
    position: relative;

    display: block;

    width: 21px;
    height: 1px;

    transition: 0.5s transform cubic-bezier(0.19, 1, 0.22, 1),
      0.5s color ease-in-out;

    border-radius: 2px;
    background-color: #000;
  }

  .bottom {
    margin-top: 6px;
  }
}

.showMenu {
  .name {
    color: #fff;
  }
  nav {
    font-size: 2em;
    font-weight: var(--bold);

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;

    animation: bg 0.2s ease-out backwards;
    text-align: center;

    color: #fff;
    background-color: rgba(0, 0, 0, 0.95);
  }
  .top,
  .bottom {
    background-color: #fff;
  }
  .top {
    transform: rotate(-45deg);
  }
  .bottom {
    margin-top: -1px;

    transform: rotate(45deg);
  }
}

.topbar a,
.link {
  position: relative;

  transition: 0.5s color cubic-bezier(0.19, 1, 0.22, 1);
  text-decoration: none;
}

.link:after {
  position: absolute;
  bottom: 5%;
  left: 0;

  width: 100%;
  height: 8px;

  content: '';
  transition: 0.5s transform cubic-bezier(0.19, 1, 0.22, 1);
  transform: scaleX(0);
  transform-origin: right center;

  background-color: rgba(0, 0, 0, 0.1);

  will-change: transform;
}

.link:hover {
  color: var(--text-color);
}

.link:hover:after {
  transform: scaleX(1);
  transform-origin: left center;
}
