/*
 * Base
 * ====
 *
 */

 ::selection {
  background-color: #fe2a00;
  color: black;
}

* {
  box-sizing: border-box;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  background-color: var(--fade-color);
}

body {
  font: 130%/1.625 var(--sans-serif);
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'calt', 'liga', 'onum', 'pnum';
  text-rendering: optimizeLegibility;

  position: relative;

  overflow-x: hidden;

  max-width: var(--outer-rim);
  margin: 0 auto;
  padding: 0;

  color: var(--text-color);
  background-color: #f8f8f8;

  @include large {
    font-size: 130%;
  }
  @include medium {
    font-size: 110%;
  }
  @include small {
    font-size: 120%;
  }
}

.container {
  position: relative;

  max-width: var(--content-width);
  margin: 0 auto;
  padding: 0 var(--unit-half);

  &.slim {
    max-width: 650px;
  }
}

.button {
  font-size: 80%;
  font-weight: var(--bold);

  display: inline-block;

  padding: 0.8rem 1.2rem;

  transition: 0.2s opacity cubic-bezier(0, 0.405, 0, 1.285),
    0.2s transform cubic-bezier(0, 0.405, 0, 1.285);
  text-decoration: none;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: #fff;
  border-radius: 4px;
  background-color: var(--accent-color);

  &:hover {
    opacity: 0.8;
    color: #fff;
  }

  &:active {
    transform: scale(0.95);
  }

  &.secondary {
    background-color: var(--meta-color);
  }

  & + .button {
    margin-left: 1rem;
  }
}

.grid {
  display: flex;
  flex-flow: wrap;
}

.col {
  margin-right: var(--unit);

  @include small {
    margin-right: 0;

    flex: 0 0 100%;
  }

  &.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  flex: 0 0 calc(50% - var(--unit-half));

  &:nth-child(2) {
    margin-right: 0;
  }
}
