/*
 * Fonts
 * =====
 *
 */

// Noe Display
@font-face {
  font-family: 'Noe Display';
  src: url('../fonts/Noe-Display-Bold.woff') format('woff');
}

// Noe Text
@font-face {
  font-family: 'Noe Text';
  src: url('../fonts/noe-text-bold.woff') format('woff');
}

// NB International

@font-face {
  font-family: 'NB';
  src: url('../fonts/NB-International-Pro-Regular.woff') format('woff');
}

// Syne
@font-face {
  font-family: 'Syne Bold';
  src: url('../fonts/Syne-Bold.woff') format('woff');
}