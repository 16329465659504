/*
 * Work/Posts
 * ==========
 *
 */

figure {
  position: relative;
  width: 100%;

  margin: 0;

  text-align: center;

  color: var(--meta-color);

  &:hover .link {
    opacity: 1;
  }

  &.background {
    padding: var(--unit);

    background-color: var(--fade-color);
  }

  &.slim {
    max-width: calc(50% - var(--unit-half));
  }

  &.right {
    margin-left: auto;
  }

  &.mt {
    margin-top: var(--unit);
  }

  &.mb {
    margin-bottom: var(--unit);
  }

  &.frame {
    .gatsby-image-outer-wrapper:before {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
      content: '';
      z-index: 2;
    }
  }

  .link {
    position: absolute;
    z-index: 1;
    top: var(--unit-quarter);
    right: var(--unit-quarter);

    transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    opacity: 0;
  }

  img {
    // max-width: calc(50% - var(--unit));
  }
}

.phone-framer {
  position: relative;
  overflow: hidden;

  video {
    position: absolute;
    left: 50%;
    width: 29%;
    top: 16.25%;
    transform: translateX(-50%);
  }
}

figcaption {
  text-align: center;
  font-size: 80%;
  line-height: 1.5;
  padding: 0 var(--unit);
  margin-top: var(--unit-half);
}

article {
  position: relative;

  overflow: auto;

  & > div {
    display: flex;
    flex-flow: row wrap;
  }
}

.intro {
  width: 100%;

  color: var(--meta-color);

  ul {
    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;

    li {
      position: relative;

      display: flex;
      align-items: center;

      & + li {
        margin-top: 0.5rem;

        @include small {
          margin-top: 0;
        }
      }

      &:after {
        width: var(--unit);
        height: 2px;
        margin-left: 0.85rem;

        content: '';

        background-color: var(--border-color);
      }
    }
  }
}

.block {
  max-width: calc(50% - var(--unit-half));
  margin-bottom: var(--unit-double);

  @include small {
    max-width: 100%;

    flex-shrink: 0;
  }

  flex: 1 1 100%;

  &.pull {
    margin-bottom: var(--unit);
  }

  &.mobile-pull {
    @include small {
      margin-bottom: var(--unit);
    }
  }

  &.center {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;

    text-align: center;

    flex-shrink: 0;

    @include small {
      text-align: left;
    }
  }

  &.full {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;

    flex-shrink: 0;
  }

  &.right {
    margin-left: auto;
  }

  &.vc {
    display: flex;
    align-items: center;
    align-content: center;
    flex-flow: row wrap;
  }

  p {
    padding-right: var(--unit-half);

    & + h3 {
      margin-top: var(--unit-double);
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.pagination {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    margin-bottom: var(--unit-double);

    h2 {
      margin: 0;
    }
  }

  a {
    text-decoration: none;

    color: var(--meta-color);

    &:hover {
      color: var(--accent-color);
    }
  }

  .block,
  .post {
    margin-bottom: 0;

    @include small {
      margin-bottom: var(--unit-half);
    }
  }
}

article h2 + p {
  max-width: calc(50% - var(--unit));
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;

  @include medium {
    max-width: 100%;
  }
}

article h2,
article h3,
article h4,
article h5,
article h6 {
  margin-top: 0;
  margin-bottom: var(--unit-half);
}

// Blur-up base64 thumbs look quite dirty, let's fix them up with some actual blurring.
.gatsby-image-wrapper img[src^='data:image'] {
  filter: blur(30px);
  transform: scale(1.1);
}
