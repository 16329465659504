/*
 * Typography
 * ==========
 *
 */

h1,
.h1 {
  font-size: 2rem;
  font-weight: var(--bold);
  line-height: 1.3;

  margin: 0;

  letter-spacing: -0.03em;

  @include medium {
    font-size: 1.5rem;
  }
  @include small {
    font-size: 1.4rem;

    margin-bottom: var(--unit);
  }

  em {
    font-style: normal;

    display: inline;

    transform: none;

    // box-shadow: inset 0 -0.1em 0 #fff, inset 0 -0.5em 0 var(--backdrop);
  }
}

h2,
.h2 {
  font-size: 1.6em;
  font-weight: var(--bold);
  line-height: 1.1;

  margin-bottom: var(--unit);

  letter-spacing: -0.03em;

  @include small {
    font-size: 1.3em;
  }
}

h3,
.h3 {
  font-size: 1.6rem;
  font-weight: var(--bold);
  line-height: 1.37;

  margin: 0.6rem 0 0;

  letter-spacing: -0.02em;

  @include small {
    font-size: 1.2em;
  }
}

h6 {
  font-size: 80%;
  font-weight: normal;
  font-feature-settings: 'smcp', 'c2sc', 'lnum';
  line-height: 1;

  margin: 1.5em 0 1em;

  letter-spacing: 1px;
  text-transform: uppercase;

  color: var(--meta-color);

  @include small {
    margin: var(--unit) 0 var(--unit-quarter);
  }

  &:after {
    width: var(--unit);
    height: 1px;
    margin-left: 0.85rem;

    background-color: var(--border-color);
    // content: '';
  }
}

hgroup {
  h1 {
    margin-top: 0.2em;
  }
}

a {
  text-decoration: underline;

  color: inherit;

  text-decoration-skip: ink;
}

a.external {
  position: relative;

  margin-right: 0.85em;

  &:before {
    line-height: 1;

    position: absolute;
    top: 0;
    right: -0.85em;

    display: block;

    padding-left: 0.8em;

    content: '\2197';
    text-align: right;
  }
}

p {
  font-family: var(--serif);
  font-size: 90%;

  margin-top: 0;
  margin-bottom: 30px;

  hyphens: auto;

  color: var(--paragraph-color);

  @include small {
    margin-bottom: 1em;
  }

  a {
    transition: 0.15s box-shadow cubic-bezier(0.645, 0.045, 0.355, 1);
    text-decoration: none;

    box-shadow: inset 0 -0.4em 0 rgba(20, 21, 28, 0.1);

    &:hover {
      box-shadow: inset 0 -2em 0 rgba(20, 21, 28, 0.1);
    }
  }
}

// em {
//   font-style: normal;

//   display: inline-block;

//   transform: skewX(-8deg);
// }

blockquote {
  margin: 0;
  padding-left: 2em;

  p {
    color: var(--meta-color);
  }
}

.caps {
  font-feature-settings: 'smcp', 'c2sc', 'lnum';

  letter-spacing: 0.05em;
}

.small {
  font-size: 80%;
  font-family: var(--sans-serif);
  color: var(--meta-color);
  hyphens: none;
}
