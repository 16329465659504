/*
 * Header/Hero
 * ===========
 *
 */

header.header {
  position: relative;

  display: flex;
  flex-flow: wrap;

  height: 100%;
  margin-bottom: var(--unit-double);

  @include small {
    margin-bottom: var(--unit);
    padding-top: calc(48px + var(--unit-double));

    &.grid {
      flex-flow: column-reverse;
    }
  }

  .title {
    @include small {
      margin-bottom: var(--unit-half);
    }
  }

  &.loaded {
    .title {
      animation: fadeInBottom 1s 0.5s cubic-bezier(0.19, 1, 0.22, 1) backwards;
    }

    .summary,
    .intro {
      animation: fadeInBottom 1s 0.75s cubic-bezier(0.19, 1, 0.22, 1) backwards;
    }

    .title,
    .summary,
    .intro {
      opacity: 1;
    }
  }

  .title,
  .summary,
  .intro {
    opacity: 0;
  }

  .summary,
  .intro {
    margin-top: auto;
    align-self: flex-end;
  }

  h6 {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.meta {
  z-index: 3;

  display: flex;
  align-content: space-between;
  flex-flow: wrap;

  height: 100%;
}

.figure {
  position: relative;

  overflow: hidden;
  // height: 600px;

  min-height: 350px;

  @include small {
    min-height: 150px;
    margin-top: var(--unit);
  }

  video {
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    min-width: 100%;
    min-height: 100%;

    object-fit: cover;
    object-position: 50% 50%;
  }
}

.img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include medium {
    background-position: center center;
  }
}

@keyframes fadeInBottom {
  0% {
    transform: translateY(20px);

    opacity: 0;
  }

  100% {
    transform: translateY(0);

    opacity: 1;
  }
}
