/*
 * Reset
 * =====
 *
 */

img,
video {
  max-width: 100%;
}

video {
  width: 100%;
}

ul p,
ol p {
  margin: 0;
}

ul,
ol {
  padding: 0;

  list-style-position: outside;
}

ul ul,
ul ol,
ol ol {
  padding-left: var(--unit-quarter);
}

hr {
  width: 100%;
  height: 2px;
  margin-top: 0;
  margin-bottom: var(--unit-double);

  border: none;
  background-color: var(--border-color);
}

a {
  transition: 0.1s color ease-in-out;
}
